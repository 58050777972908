// Default imports
import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import SectionHeader from "../components/SectionHeader";

import slugify from "slugify";

const GenericWideSection = ({
  header,
  label,
  slug,
  text,
  children,
  vertical,
  smallContent,
  containerClassName,
  contentClassName,
}) => {
  let col1sizes, col2sizes;
  switch (true) {
    case vertical:
      col1sizes = { xs: "24", lg: "14" };
      col2sizes = { xs: "24" };
      break;

    case smallContent:
      col1sizes = {
        xs: "24",
        md: "12",
        lg: "12",
        xl: "8",
      };
      col2sizes = {
        xs: "24",
        md: { span: "11", offset: "1" },
        lg: { span: "11", offset: "1" },
        xl: { span: "10", offset: "2" },
      };
      break;

    default:
      col1sizes = {
        xs: "24",
        md: "9",
      };
      col2sizes = {
        xs: "24",
        md: { span: "14", offset: "1" },
      };
  }

  return (
    <Container
      className={`generic-wide-section ${vertical ? "-vertical" : ""} ${
        containerClassName || ""
      }`}
      id={
        slug ||
        slugify(header || "section", {
          lower: true,
          strict: true,
          locale: "pl",
        })
      }
    >
      <Row className="generic-wide-section__wrapper">
        {(header || text) && (
          <Col className="generic-wide-section__desc" {...col1sizes}>
            {header && (
              <div className="generic-wide-section__headline">
                <SectionHeader header={header} labelTop={label} />
              </div>
            )}
            {text && (
              <p
                className="generic-wide-section__text"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            )}
          </Col>
        )}

        <Col
          className={`generic-wide-section__content-wrapper ${
            contentClassName || ""
          }`}
          {...col2sizes}
        >
          {children}
        </Col>
      </Row>
    </Container>
  );
};

export default GenericWideSection;
