// Default imports
import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import GenericSection from "../components/GenericSection";
import GenericWideSection from "./GenericWideSection";
import WpImageFluid from "./WpImageFluid";
import ContentBox from "./ContentBox";

import slugify from "slugify";

const ImageSection = ({ imageSection, ...props }) => {
  switch (imageSection?.variant) {
    case "blue-box":
    case "no-box":
      return <ImageSectionWithTextBoxed {...imageSection} {...props} />;
    
    case "no-box-full-width":
      return <ImageSectionWithTextBoxedFullWidth {...imageSection} {...props} />;
  
    case "oversize-grey-background":
      return <ImageSectionWithText {...imageSection} {...props} />;

    case "wide":
      return <ImageSectionWide {...imageSection} {...props} />;

    case "wide-vertical":
      return <ImageSectionWide vertical {...imageSection} {...props} />;

    case "full-width":
      return (
        <ImageSectionWide
          vertical
          containerClassName="-full-width"
          {...imageSection}
          {...props}
        />
      );

    default:
      return <></>;
  }
};

const ImageSectionWide = ({ title, image, ...props }) => (
  <GenericWideSection
    header={title}
    smallContent={!props?.vertical}
    containerClassName="py-0"
    contentClasName="-wide"
    {...props}
  >
    <WpImageFluid
      className={`image-section__image w-100`}
      image={image}
      style={{ width: "100%", maxWidth: "none" }}
    />
  </GenericWideSection>
);

const ImageSectionWithText = ({ title, image, ...props }) => (
  <GenericSection header={title} {...props}>
    <WpImageFluid className={`image-section__image `} image={image} />
  </GenericSection>
);

const ImageSectionWithTextBoxed = ({
  title,
  slug,
  text,
  image,
  button,
  variant,
  flipped,
}) => {
  return (
    <Container
      id={
        slug ||
        slugify(title || "image-section", {
          lower: true,
          strict: true,
          locale: "pl",
        })
      }
    >
      <Row className={`image-section -${variant} ${flipped && "-flipped"}`}>
        <Col xs="24" md="24" lg="10" xl="10">
          <ContentBox
            title={title}
            text={text}
            button={button}
            variant={variant}
            displayLogo={variant === "blue-box"}
          />
        </Col>

        <Col
          xs="24"
          md="16"
          lg="12"
          xl="9"
          className="d-flex justify-content-center cmb-6 cmb-lg-0"
        >
          <WpImageFluid className="image-section__image" image={image} />
        </Col>
      </Row>
    </Container>
  );
};

const ImageSectionWithTextBoxedFullWidth = ({
  title,
  slug,
  text,
  image,
  button,
  variant,
  flipped,
}) => {
  return (
    <Container
      id={
        slug ||
        slugify(title || "image-section", {
          lower: true,
          strict: true,
          locale: "pl",
        })
      }
    >
      <Row className={`image-section -${variant} ${flipped && "-flipped"}`}>
        <Col xs="22" md="24" lg="10" className={`${flipped && "offset-lg-2"}`}>
          <ContentBox
            title={title}
            text={text}
            button={button}
            variant={variant}
            displayLogo={variant === "blue-box"}
          />
        </Col>

        <Col
          xs="24"
          md="16"
          lg="11"
          className={`d-flex justify-content-center cmb-6 cmb-lg-0 ${!flipped && "offset-lg-2"}`}
        >
          <WpImageFluid className="image-section__image" image={image} />
        </Col>
      </Row>
    </Container>
  );
};

export default ImageSection;
