// Default imports
import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import ButtonText from "../components/ButtonText";
import Logo from "../images/icon-transparent.svg";

const ContentBox = ({ variant, title, text, button, displayLogo }) => (
  <div className={`content-box -${variant}`}>
    <div className="content-box__headline">
      {displayLogo && <Logo className="content-box__logo" />}
      <h3
        className="content-box__header"
        dangerouslySetInnerHTML={{ __html: title }}
      />
    </div>
    <p
      className="content-box__description"
      dangerouslySetInnerHTML={{ __html: text }}
    />
    {button && (
      <Col xs="24">
        <ButtonText variant="red" {...button}>
          {button?.title}
        </ButtonText>
      </Col>
    )}
  </div>
);

export default ContentBox;
