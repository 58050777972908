import React from "react";
import { FixSvg } from "../helpers/svgHelpers";
import Img from "gatsby-image";

const WpImageFluid = ({ image, ...attrs }) => {
  return image ? (
    image.localFile.extension === "svg" ? (
      <FixSvg svg={image.localFile.childSvg?.content.data} {...attrs} />
    ) : (
      <Img
        fluid={image.localFile.childImageSharp?.fluid}
        alt={image.altText || ""}
        {...attrs}
      />
    )
  ) : (
    <></>
  );
};
export default WpImageFluid;
