// Default imports
import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import WpImageFluid from "./WpImageFluid";
import SectionHeader from "../components/SectionHeader";

import slugify from "slugify";

const GenericSection = ({
  header,
  slug,
  text,
  image,
  oversizeContent,
  containerClassName,
  contentClassName,
  vertical,
  children,
}) => {
  let col1sizes, col2sizes;
  switch (true) {
    case vertical:
      col1sizes = { xs: "24" };
      col2sizes = { xs: "24" };
      break;

    default:
      col1sizes = {
        xs: "24",
        md: oversizeContent ? "24" : "9",
        lg: oversizeContent ? "11" : "",
        xl: oversizeContent ? "10" : "",
      };
      col2sizes = {
        xs: "24",
        md: oversizeContent ? "24" : { span: "13", offset: "2" },
        lg: oversizeContent ? { span: "11", offset: "2" } : {},
        xl: oversizeContent ? { span: "8", offset: "3" } : {},
      };
  }
  return (
    <Container
      className={`generic-section ${containerClassName || ""}`}
      id={
        slug ||
        slugify(header || "section", {
          lower: true,
          strict: true,
          locale: "pl",
        })
      }
    >
      <Row>
        <Col {...col1sizes}>
          <div
            className={`generic-section__headline ${
              vertical ? "-slim-bottom" : ""
            }`}
          >
            <SectionHeader header={header} />
            <p
              className="generic-section__content-text"
              dangerouslySetInnerHTML={{ __html: text }}
            />
            <WpImageFluid className={`generic-section__image `} image={image} />
          </div>
        </Col>
        <Col {...col2sizes}>
          <div
            className={`generic-section__content ${
              oversizeContent ? " -oversize" : ""
            } ${contentClassName || ""}`}
          >
            {children}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default GenericSection;
